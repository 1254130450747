import React, { useState, useEffect, DOMElement } from "react";
import { Url } from "url";
import styles from "styles/components/Card.module.scss";
import Image from "next/image";
import Link from "next/link";
import dayjs from "dayjs";

import { fetchData, postReaction, setInnerHtml, slugify, getFGDThumbnail, fetchPublicURL } from "helpers/functions";
import { useRecoilState, useRecoilValue } from "recoil";

import ArticleStamps from "components/article/ArticleStamps";
import AwardsBadge from "components/creative-effectiveness/AwardsBadge";
import BookmarkButton from "../bookmarks/BookmarkButton";
import CreativeBookmarkButton from "components/creative-effectiveness/BookmarkButton";

import { dataTopics, customCategories } from "helpers/sampleData";

// Interfaces
import { IfaceTopicApi, IPageApi } from "helpers/interfaces";

// API
import {
  topicListState
} from "state/atoms";

// Icons
import IconThumbsUp from "images/icon-thumbsup.svg";
import IconThumbsDown from "images/icon-thumbsdown.svg";
import IconWantMore from "images/reaction-want-more.svg";
import IconHamburglar from "images/icon-hamburglar.svg";
import IconViews from "images/icon-views.svg";
import Globe from "images/icon-globe.svg";
import Folder from "images/icon-folder.svg";
import IconFile from "images/icon-file.svg";
import HappyMeal from "images/ui-happy-meal-logo.svg";
import FoodLibrary from "images/food-library.svg";
import DOCX from "images/markdown.svg";
import IconDocument from "images/icon-file-document.svg";
import IconBookmarked from "images/icon-isbookmarked.svg";
import IconBrief from "images/icon-brief.svg";
import IconCampaign from "images/icon-campaign.svg";
import IconCE from "images/icon-ce-tab.svg";
import IconFGD from "images/icon-fgd-tab.svg";
import IconArticle from "images/icon-article.svg";
import IconDownload from "images/icon-download.svg";

// Functions
function capitalizeFirstLetter(string: any) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type Props = {
  // From article API
  // TODO: Tighten these up when actual API hits
  hit?: any;
  article_id: number;
  country: string;
  title: string;
  date: Date | string; // TODO: Decide Date or string
  year?: any;
  slug: string;
  badges?: any;
  category?: any;
  short_description?: string | null;
  viewCount: number;
  video?: string;
  isFeatured?: boolean;
  image?: {
    src: string;
    alt: string;
  };
  // From User API?
  hasRead?: boolean;
  hasBookmarked: boolean;
  // For FE / Display Only
  displayTitleOnly?: boolean;
  displayLarge?: boolean;
  additionalClasses?: string;
  displayDark?: boolean;
  reactions?: IfaceReactions;
  hideBookmarkIcon?: boolean;
  displayLong?: boolean;
  displayTruncated?: boolean;
  setToggleArticle?: any;
  topics?: any;
  primary_topic?: any;
  additional_topics?: any;
  object_type?: any;
  fileUrl?: any;
  trendingNumber?: any;
  award?: string;
  custom_type?: any;
  homepage_card?: boolean;
};

declare const window: any;

function Card({
  hit,
  article_id,
  country,
  category = null,
  badges,
  slug,
  title,
  video,
  image,
  short_description,
  date,
  year,
  additionalClasses = '',
  hasRead,
  hasBookmarked,
  viewCount,
  displayLarge,
  displayTitleOnly,
  isFeatured,
  displayDark,
  reactions,
  hideBookmarkIcon,
  displayLong = false,
  displayTruncated,
  setToggleArticle = null,
  topics = null,
  primary_topic = null,
  additional_topics = null,
  object_type,
  fileUrl,
  trendingNumber = null,
  award,
  custom_type,
  homepage_card = false
}: Props) {

  // Use state for current window width
  const [width, setWidth] = useState(window ? window.innerWidth : 0);

  // We're going to ignore 'displayLong' prop if we're on mobile, using 'showLong' instead in our elements below for conditional appearance
  // let showLong: any = displayLong;

  let stamps: any;
  if (typeof badges === "string") {
    stamps = JSON.parse(badges);
  } else if (typeof badges === "object") {
    stamps = badges;
  }

  // When component renders or window is resized, grab new window width
  const [showLong, setshowLong] = useState<boolean>(displayLong && width >= 700 ? displayLong : false);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 700 && showLong) {
      setshowLong(false);
    } else if (displayLong && window.innerWidth >= 700 && !showLong) {
      setshowLong(true);
    }
  }, [width, window]);

  // End Window Dimensions

  // Bookmarked State
  const [isBookmarked, setIsBookmarked] = useState(hasBookmarked || false);

  useEffect(() => {
    if (hasBookmarked !== undefined) {
      setIsBookmarked(hasBookmarked);
    }
  }, [hasBookmarked]);

  //Only show topics from the API
  let finalTopics: any = [];
  const [publishedTopics, setPublishedTopics] = useState<any>([]);
  const availableTopics = useRecoilValue(topicListState);

  useEffect(() => {
	if (availableTopics.loaded && !availableTopics.error && availableTopics.res.data.length > 0) {
		setPublishedTopics(availableTopics.res.data.filter((topic: any) => !topic.slug.includes('/draft') && !topic.slug.includes('/archived')));
	  }
  }, [availableTopics]);

  if (topics && topics.length > 0 && publishedTopics && publishedTopics.length > 0) {
		if (object_type === 'article') {
		topics.map((u: any) => {
			if (u) {
				publishedTopics.filter((n: any) => {
					if (n.title === u || n.title === u.topic || n.title === u.title) {
						finalTopics.push(n);
					};
				});
			}
		})
		} else {
		topics.map((topic: any) => {
			let topicObj: any = {};
			if (typeof topic === 'string') {
				topicObj.title = topic;
			} else {
				topicObj = topic;
				if (topicObj && topicObj.title === undefined && topicObj.name) {
					topicObj.title = topicObj.name;
				}
			}
			publishedTopics.forEach((n: any) => {
				if (n.title === topicObj.title) {
					finalTopics.push(n);
				};
			});
		})
	}
  }

  if (finalTopics.length > 0) {
    finalTopics.map((u: any) => {
      if (u) {
        if (!u.title && typeof u !== 'string') {
          u['title'] = u.topic;
        }
        if (u === 'Global Food Marketing' || u === 'Global Family Marketing' || (typeof u !== 'string' && (u.title === 'Global Food Marketing' || u.title === 'Global Family Marketing'))) {
          u = 'Global Food Library';
        }
      }
    })
  }

  // Custom Card Category
  if (object_type === 'customCard') {
    if (topics && topics.length > 0 && topics[0] && customCategories && customCategories[parseInt(topics[0])]) {
      let customTopic: any = {};
      customTopic.title = customCategories[parseInt(topics[0])];
      finalTopics = [customTopic];
    }
  }

  //Country String
  let countryString: any = '';
  if (country) {
    if (Array.isArray(country) && country.length > 0) {
      countryString = country.join(', ');
    } else {
      countryString = country;
    }
  }

  //Google Analytics class
  let ga_class: any = 'article-link';

  //Object Type specific content
  // Format the date
  let displayDate: any = year ? year : dayjs(date).format("MMM DD, YYYY");
  // let link: any = '';
  let link: string = `/articles/${slug}`;
  let linkTarget: string = '';
  let publicURL: any = fetchPublicURL();
  let typeIcon: any = <IconArticle />;
  let typeLabel: string = 'Article';
  let showMeta: boolean = true;
  let isCE: boolean = false;
  let isFile: boolean = false;
  let fgdThumbnail: any = null;
  let fgdThumbnailClass: any = null;
  let breadcrumbs: any = null;

  switch (object_type) {
    case 'stravito':
      ga_class = 'file-link';
      typeIcon = <DOCX />;
      typeLabel = 'File';
      link = fileUrl;
      linkTarget = '_blank';
      showMeta = false;
      isFile = true;
      break;
    case 'global_family_marketing':
      ga_class = 'global-food-library-link';
      typeIcon = <FoodLibrary />;
      link = fileUrl;
      linkTarget = '_blank';
      typeLabel = 'Global Food Library';
      showMeta = false;
      isFile = true;
      break;
    case 'global_food_marketing':
      ga_class = 'global-food-library-link';
      typeIcon = <FoodLibrary />;
      link = fileUrl;
      linkTarget = '_blank';
      typeLabel = 'Global Food Library';
      showMeta = false;
      isFile = true;
      break;
    case 'happy_meal':
      ga_class = 'happy-meal-link';
      typeLabel = 'Happy Meal Page';
      typeIcon = <HappyMeal />;
      link = `/happy-meal/${slug}`;
      break;
    case 'brief':
      ga_class = 'brief-link';
      typeLabel = 'Brief';
      typeIcon = <IconBrief />;
      link = `/brief/${slug}`;
      if (!homepage_card) {
        if (hit && hit.campaign_year) {
          if (hit.campaign_year.length === 4) {
            displayDate = hit.campaign_year;
          } else {
            const convertedDate: any = new Date(hit.campaign_year * 1000);
            displayDate = convertedDate.getFullYear();
          }
        } else if (date) {
			displayDate = year ? year : dayjs(date).format("YYYY");
		} else {
          displayDate = null;
        }
      }
      isCE = true;
      break;
    case 'creative_campaign':
      ga_class = 'campaign-link';
      typeLabel = 'Creative Campaign';
      typeIcon = <IconCampaign />;
      link = `/creative/${slug}`;
      if (!homepage_card) {
        if (hit && hit.campaign_year) {
          if (hit.campaign_year.length === 4) {
            displayDate = hit.campaign_year;
          } else {
            const convertedDate: any = new Date(hit.campaign_year * 1000);
            displayDate = convertedDate.getFullYear();
          }
        } else if (date) {
			displayDate = year ? year : dayjs(date).format("YYYY");
		} else {
          displayDate = null;
        }
      }
      isCE = true;
      break;
    case 'campaign':
      ga_class = 'campaign-link';
      typeLabel = 'Creative Campaign';
      typeIcon = <IconCampaign />;
      link = `/creative/${slug}`;
      if (!homepage_card) {
        if (hit && hit.campaign_year) {
          if (hit.campaign_year.length === 4) {
            displayDate = hit.campaign_year;
          } else {
            const convertedDate: any = new Date(hit.campaign_year * 1000);
            displayDate = convertedDate.getFullYear();
          }
        } else if (date) {
			displayDate = year ? year : dayjs(date).format("YYYY");
		} else {
          displayDate = null;
        }
        if (displayDate === 'Invalid Date' && hit.campaign_year) {
          const convertedDate: any = new Date(hit.campaign_year);

          if (convertedDate) {
            displayDate = convertedDate.getFullYear();
          }
        }
      }
      isCE = true;
      break;
    case 'guideline':
      link = `/guidelines/${slug}`;
      typeLabel = 'Guideline';
      typeIcon = <IconFGD />;
      showMeta = false;
      break;
    case 'guidelines':
      link = `/guidelines/${slug}`;
      typeLabel = 'Guideline';
      typeIcon = <IconFGD />;
      if (hit.guideline_type_slug) {
        fgdThumbnail = getFGDThumbnail(hit.guideline_type_slug);
        fgdThumbnailClass = `${styles.fgdImage} ${hit.guideline_type_slug.toLowerCase()}`;
      }
      showMeta = false;
      break;
    case 'download':
      link = `/downloads/${slug}`;
      typeLabel = 'Download';
      typeIcon = <IconFGD />;
      if (title) {
        fgdThumbnail = getFGDThumbnail(slugify(title));
        fgdThumbnailClass = `${styles.fgdImage} ${slugify(title)}`;
      }
      showMeta = false;
      break;
    case 'downloads':
      link = `/downloads/${slug}`;
      typeLabel = 'Download';
      typeIcon = <IconFGD />;
      if (title) {
        fgdThumbnail = getFGDThumbnail(slugify(title));
        fgdThumbnailClass = `${styles.fgdImage} ${slugify(title)}`;
      }
      showMeta = false;
      break;
    case 'webcast':
      link = `/webcast-recordings/${slug}`;
      typeLabel = 'Webcast';
      typeIcon = <IconFGD />;
      fgdThumbnail = '/images/icon-webcast-recordings.svg';
      fgdThumbnailClass = `${styles.fgdImage} webcast`;
      showMeta = false;
      break;
    case 'fgd_page':
      link = `/${slug}`;
      typeLabel = 'Feel-Good Design';
      typeIcon = <IconFGD />;
      showMeta = false;
      break;
    case 'blog':
      typeLabel = 'Blog';
      typeIcon = <IconFGD />;
      break;
    case 'customCard':
      link = slug ? slug : hit.card_url;
      typeLabel = custom_type ? capitalizeFirstLetter(custom_type) : null;
      typeIcon = null;
      if (custom_type === 'download') {
        typeIcon = <IconDownload />;
      } else {
        typeIcon = <IconFGD />;
      }
      if (typeLabel === null) {
        typeIcon = null;
      }
      if (hit.card_category) {
        finalTopics = [hit.card_category];
      }
      if (link && !link.includes(publicURL)) {
        linkTarget = '_blank';
      }
      showMeta = false;
      break;
    case 'custom_card':
      link = slug ? slug : hit.card_url;
      typeLabel = custom_type ? capitalizeFirstLetter(custom_type) : null;
      typeIcon = null;
      if (custom_type === 'download') {
        typeIcon = <IconDownload />;
      } else {
        typeIcon = <IconFGD />;
      }
      if (typeLabel === null) {
        typeIcon = null;
      }
      if (hit.card_category) {
        finalTopics = [hit.card_category];
      }
      if (link && !link.includes(publicURL)) {
        linkTarget = '_blank';
      }
      showMeta = false;
      break;
    default:
      // link = hit && hit !== undefined ? hit.url : '#';
      // linkTarget = '_blank';
      break;
  }

  if (displayLarge && width > 1100) {
    return (
      <div
        className={`${styles.card} ${styles.largeCard}
			${additionalClasses}
			${hasRead !== false ? "" : styles.unread} ${displayDark ? styles.dark : ""}`}>
        {(countryString !== '' || category || stamps) && <div className={`${styles.super} caption small`}>
          <div className={styles.superText}>
            {countryString !== '' && <span className="country">{countryString.replace('Transnational', 'Global')}</span>}
          </div>
          {stamps && (
            <div className={styles.stamp}>
              <ArticleStamps stamps={stamps} />
            </div>
          )}
        </div>}
        <div className={styles.largeTitleBlock}>
          {(typeIcon !== null || typeLabel !== null) && (
            <p className={styles.objectType}>
              {typeIcon && (
                <>{typeIcon}</>
              )}
              <span>{typeLabel}</span>
            </p>
          )}

          <h3 className="h2 large">
            <Link href={link ? link : '#'}>
              <a className={`${styles.cardLink}} is-card-title ${ga_class}`}>{title}</a>
            </Link>
          </h3>
          <div className={styles.meta}>
            {displayDate && (
              <span className="date" data-date={displayDate}>{displayDate}</span>
            )}
            {showMeta && (
              <>
                <span className="views">
                  <IconViews />{" "}
                  <span className="count">{viewCount}</span>
                </span>
                {hideBookmarkIcon !== true && (
                  <>
                    {isCE ? (
                      <CreativeBookmarkButton
                        post={{ loaded: true, error: false, res: { data: hit } }}
                        id={hit.id}
                        slug={hit.slug}
                        objectType={object_type}
                        additionalClasses={`${styles.bookmark} card-bookmark`}
                      />
                    ) : (
                      <BookmarkButton
                        article_id={article_id}
                        article_slug={slug}
                        isBookmarked={isBookmarked}
                        setIsBookmarked={setIsBookmarked}
                        setToggleArticle={setToggleArticle}
                      />
                    )}
                  </>
                )}
                {reactions && (
                  <span className="reaction">
                    <ReactionIcons reactions={{ ...reactions }} />
                  </span>
                )}
                {stamps && (
                  <div className={styles.stamp}>
                    <ArticleStamps stamps={stamps} />
                  </div>
                )}
                {award && (
                  <AwardsBadge
                    award={award}
                    year={displayDate}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {!displayTruncated && (
          <div className="content">
            {video && <div className={styles.videoContainer} />}
            {!video && (image || fgdThumbnail) && (
              <Link href={link ? link : '#'}>
                <a className={`${styles.imageContainer} ${isFile ? styles.iconContainer : ''} ${fgdThumbnailClass ? fgdThumbnailClass : ''} ${object_type === 'stravito' ? styles.fileIcon : ''} ${ga_class}`}>
                  {!video && image && image.src && (
                    <>
                      {isFile ? (
                        <>
                          {object_type === 'stravito' ? (<IconFile />) : typeIcon}
                        </>
                      ) : fgdThumbnail ? (
                        <>
                          <img src={fgdThumbnail} alt={''} />
                        </>
                      ) : (
                        <Image
                          unoptimized={true}
                          src={image.src}
                          alt={image.alt}
                          layout="responsive"
                          objectFit="cover"
                          objectPosition="center"
                          priority={true}
                          width={282}
                          height={161}
                        />
                      )}
                    </>
                  )}
                </a>
              </Link>
            )}
            {!video && short_description && (
              <div className={`${styles.summary}`}>
                <span
                  dangerouslySetInnerHTML={setInnerHtml(
                    short_description
                  )}></span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  } else if (displayTitleOnly) {
    return (
      <div
        className={`${styles.card} titleOnlyCard ${styles.titleOnlyCard}
			${additionalClasses}
			${hasRead !== false ? "" : styles.unread} ${displayDark ? styles.dark : ""}`}>
        {(typeIcon !== null || typeLabel !== null) && (
          <p className={styles.objectType}>
            {typeIcon && (
              <>{typeIcon}</>
            )}
            <span>{typeLabel}</span>
          </p>
        )}
        <h3>
          <link href={link ? link : '#'}>
            <a className={`${styles.cardLink}} is-card-title ${ga_class}`}>{title}</a>
          </link>
        </h3>
        <div className={styles.cardBottom}>
          {(countryString !== '' || category || stamps) && <div className={`${styles.super} caption small`}>
            {displayDate && (
              <p className={styles.date} data-date={displayDate}>{displayDate}</p>
            )}
            <div className={styles.superText}>
              {countryString !== '' && (
                <div className={`${styles.countryContainer} countryContainer`}>
                  <p className={styles.country}>
                    <Globe />
                    {countryString.replace('Transnational', 'Global')}
                  </p>
                  <div className={`${styles.metaHover} metaHover`}>
                    <p className={styles.hoverLabel}>
                      <Globe />
                      <span>Market</span>
                    </p>
                    <ul>
                      <li>{countryString.replace('Transnational', 'Global')}</li>
                    </ul>
                  </div>
                </div>
              )}
              {finalTopics && finalTopics.length > 0 && (
                <div className={`${styles.categoryContainer} categoryContainer`}>
                  <p className={styles.category}>
                    <Folder />
                    {finalTopics.map((u: any) => u && u.title ? u.title : u).join(', ')}
                  </p>
                  <div className={`${styles.metaHover} metaHover`}>
                    <p className={styles.hoverLabel}>
                      <Folder />
                      <span>{object_type && (object_type === 'custom_card' || object_type === 'customCard') ? 'Category' : 'Topics'}</span>
                    </p>
                    <ul>
                      {finalTopics.map((u: any) => {
                        if (u) {
                          return (
                            <li key={`${article_id}-topic-${u.slug ? u.slug : slugify(u.title)}`}>
                              <Link href={`/topics/${u.slug ? u.slug : slugify(u.title)}`}>
                                {object_type && (object_type === 'custom_card' || object_type === 'customCard') ? (
                                  <p>{u.title}</p>
                                ) : (
                                  <a className={`topic-link`}>{u.title}</a>
                                )}
                              </Link>
                            </li>
                          )
                        }
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>}
          {!displayTruncated && showMeta && (
            <div className={styles.meta}>
              <span className="views">
                <IconViews /> <span className="count">{viewCount}</span>
              </span>
              {hideBookmarkIcon !== true && (
                <>
                  {isCE ? (
                    <CreativeBookmarkButton
                      post={{ loaded: true, error: false, res: { data: hit } }}
                      id={hit.id}
                      slug={hit.slug}
                      objectType={object_type}
                      additionalClasses={`${styles.bookmark} card-bookmark`}
                    />
                  ) : (
                    <BookmarkButton
                      article_id={article_id}
                      article_slug={slug}
                      isBookmarked={isBookmarked}
                      setIsBookmarked={setIsBookmarked}
                      setToggleArticle={setToggleArticle}
                    />
                  )}
                </>
              )}
              {reactions && (
                <span className="reaction">
                  {/* <ReactionThumbsup /> */}
                  <ReactionIcons reactions={{ ...reactions }} />
                </span>
              )}
              {stamps && (
                <span className={styles.stamp}>
                  <ArticleStamps stamps={stamps} />
                </span>
              )}
              {award && (
                <AwardsBadge
                  award={award}
                  year={displayDate}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`${styles.card} ${showLong ? styles.cardLong : ""}
			${additionalClasses}
      ${additionalClasses === 'gridCard' && styles.gridCard}
			${hasRead !== false ? "" : styles.unread}
			${displayDark ? styles.dark : ""} ${displayLarge ? styles.largeCard : ""}`}>
        <div className={`${styles.body} card-body`}>
          {trendingNumber !== null && (
            <div className={`${styles.trendingNumber} trendingNumber`}>
              <p><span>#</span>{trendingNumber + 1}</p>
            </div>
          )}
          {additionalClasses !== 'gridCard' && (typeIcon !== null || typeLabel !== null) && width >= 700 && (
            <p className={styles.objectType}>
              {typeIcon && (
                <>{typeIcon}</>
              )}
              <span>{typeLabel}</span>
            </p>
          )}
          {!showLong && (!additionalClasses.includes('gridCard')) && width >= 700 && (
            <h3>
              {linkTarget === '_blank' ? (
                <a href={link ? link : '#'} target={'_blank'} className={`${styles.cardLink} ${ga_class}`}>{title}</a>
              ) : (
                <Link href={link ? link : '#'}>
                  <a className={`${styles.cardLink} ${ga_class}`}>{title}</a>
                </Link>
              )}
            </h3>
          )}
          <div className="content">
            {showLong && width >= 700 && (
              <h3>
                {linkTarget === '_blank' ? (
                  <a href={link ? link : '#'} target={'_blank'} className={`${styles.cardLink} ${ga_class}`}>{title}</a>
                ) : (
                  <Link href={link ? link : '#'}>
                    <a className={`${styles.cardLink} ${ga_class}`}>{title}</a>
                  </Link>
                )}
              </h3>
            )}
            {video && <div className={styles.videoContainer} />}
            {object_type === 'article' || object_type === 'articles' || object_type === 'brief' || object_type === 'campaign' || object_type === 'customCard' || additionalClasses.includes('gridCard') ? (
              <>
                {!video && (image || fgdThumbnail) && !showLong && (
                  <>{linkTarget === '_blank' ? (
                    <a href={link ? link : '#'} target={'_blank'} className={`${styles.imageContainer} ${isFile || fgdThumbnail ? styles.iconContainer : ''} ${fgdThumbnailClass ? fgdThumbnailClass : ''} ${object_type === 'stravito' ? styles.fileIcon : ''} ${ga_class}`}>
                      {!video && image && image.src && (
                        <>
                          {isFile ? (
                            <>
                              {object_type === 'stravito' ? (<IconFile />) : typeIcon}
                            </>
                          ) : fgdThumbnail ? (
                            <>
                              <img src={fgdThumbnail} alt={''} />
                            </>
                          ) : (
                            <Image
                              unoptimized={true}
                              src={image.src}
                              alt={image.alt}
                              layout="responsive"
                              objectFit="cover"
                              objectPosition="center"
                              priority={true}
                              width={282}
                              height={161}
                            />
                          )}
                        </>
                      )}
                    </a>
                  ) : (
                    <Link href={link ? link : '#'}>
                      <a className={`${styles.imageContainer} ${isFile ? styles.iconContainer : ''} ${fgdThumbnailClass ? fgdThumbnailClass : ''} ${object_type === 'stravito' ? styles.fileIcon : ''} ${ga_class}`}>
                        {!video && image && image.src && (
                          <>
                            {isFile ? (
                              <>
                                {object_type === 'stravito' ? (<IconFile />) : typeIcon}
                              </>
                            ) : fgdThumbnail ? (
                              <>
                                <img src={fgdThumbnail} alt={''} />
                              </>
                            ) : (
                              <Image
                                unoptimized={true}
                                src={image.src}
                                alt={image.alt}
                                layout="responsive"
                                objectFit="cover"
                                objectPosition="center"
                                priority={true}
                                width={282}
                                height={161}
                              />
                            )}
                          </>
                        )}
                      </a>
                    </Link>
                  )}</>
                )}
              </>
            ) : (
              <>
                {!showLong && !additionalClasses.includes('bookmark-card') && !additionalClasses.includes('gridCard') && object_type !== 'customCard' && (
                  <>
                    {linkTarget === '_blank' ? (
                      <a href={link ? link : '#'} target={'_blank'} className={`${styles.imageContainer} ${styles.iconContainer} ${object_type === 'happy_meal' && styles.happyMealContainer + ' happyMealContainer'} ${ga_class}`}>
                        {(object_type === 'stravito') ? <DOCX /> : (object_type === 'happy_meal') ? <HappyMeal /> : <FoodLibrary />}
                      </a>
                    ) : (
                      <Link href={link ? link : '#'}>
                        <a className={`${styles.imageContainer} ${styles.iconContainer} ${object_type === 'happy_meal' && styles.happyMealContainer + ' happyMealContainer'} ${ga_class}`}>
                          {(object_type === 'stravito') ? <DOCX /> : (object_type === 'happy_meal') ? <HappyMeal /> : <FoodLibrary />}
                        </a>
                      </Link>
                    )}
                  </>
                )}
              </>
            )}
            {((!showLong && (additionalClasses && additionalClasses.includes('gridCard'))) ||
              (!video && short_description) ||
              (!displayTruncated && showMeta) || width < 700) && (
                <div className={styles.textContainer}>
                  {(additionalClasses === 'gridCard' || width < 700) && (typeIcon !== null || typeLabel !== null) && (
                    <p className={styles.objectType}>
                      {typeIcon && (
                        <>{typeIcon}</>
                      )}
                      <span>{typeLabel}</span>
                    </p>
                  )}
                  {(!showLong && (additionalClasses && additionalClasses.includes('gridCard')) || width < 700) && (
                    <h3>
                      {linkTarget === '_blank' ? (
                        <a href={link ? link : '#'} target={'_blank'} className={`${styles.cardLink} ${ga_class}`}>{title}</a>
                      ) : (
                        <Link href={link ? link : '#'}>
                          <a className={`${styles.cardLink} ${ga_class}`}>{title}</a>
                        </Link>
                      )}
                    </h3>
                  )}
                  {!video && short_description && (
                    <div className={`${styles.summary}`}>
                      <span
                        dangerouslySetInnerHTML={setInnerHtml(
                          short_description
                        )}></span>
                    </div>
                  )}
                  {!displayTruncated && showMeta && (
                    <div className={styles.meta}>
                      <span className="views">
                        <IconViews /> <span className="count">{viewCount}</span>
                      </span>
                      {hideBookmarkIcon !== true && (
                        <>
                          {isCE ? (
                            <CreativeBookmarkButton
                              post={{ loaded: true, error: false, res: { data: hit } }}
                              id={hit.id}
                              slug={hit.slug}
                              objectType={object_type}
                              additionalClasses={`${styles.bookmark} card-bookmark`}
                            />
                          ) : (
                            <BookmarkButton
                              article_id={article_id}
                              article_slug={slug}
                              isBookmarked={isBookmarked}
                              setIsBookmarked={setIsBookmarked}
                              setToggleArticle={setToggleArticle}
                            />
                          )}
                        </>
                      )}
                      {reactions && (
                        <span className="reaction">
                          {/* <ReactionThumbsup /> */}
                          <ReactionIcons reactions={{ ...reactions }} />
                        </span>
                      )}
                      {stamps && (
                        <span className={`stamp ${styles.stamp}`}>
                          <ArticleStamps stamps={stamps} />
                        </span>
                      )}
                      {award && (
                        <AwardsBadge
                          award={award}
                          year={displayDate}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
          </div>
          {displayTruncated && stamps && (
            <span className={`stamp ${styles.stamp}`}>
              <ArticleStamps stamps={stamps} />
            </span>
          )}

          {(displayDate || countryString !== '' || category || stamps || (finalTopics && finalTopics.length > 0)) && (
            <div className={`cardBottom ${styles.cardBottom}`}>
              <div className={`${styles.super} caption small`}>
                <div className={`superText ${styles.superText}`}>
                  {displayDate && (
                    <p className={`date ${styles.date}`} data-date={displayDate}>{displayDate}</p>
                  )}
                  {countryString !== '' && (
                    <div className={`${styles.countryContainer} countryContainer`}>
                      <p className={styles.country}>
                        <Globe />
                        {countryString.replace('Transnational', 'Global')}
                      </p>
                      <div className={`${styles.metaHover} metaHover`}>
                        <p className={styles.hoverLabel}>
                          <Globe />
                          <span>Market</span>
                        </p>
                        <ul>
                          <li>{countryString.replace('Transnational', 'Global')}</li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {finalTopics && finalTopics.length > 0 && (
                    <div className={`${styles.categoryContainer} categoryContainer`}>
                      <p className={styles.category}>
                        <Folder />
                        {finalTopics.map((u: any) => u && u.title ? u.title : u).join(', ')}
                      </p>
                      <div className={`${styles.metaHover} metaHover`}>
                        <p className={styles.hoverLabel}>
                          <Folder />
                          <span>{object_type && (object_type === 'custom_card' || object_type === 'customCard') ? 'Category' : 'Topics'}</span>
                        </p>
                        <ul>
                          {finalTopics.map((u: any, index: any) => {
                            if (u) {
                              return (
                                <li key={`${article_id}-final-topic-${index}`}>
                                  <Link href={`/topics/${u.slug ? u.slug : slugify(u.title ? u.title : u)}`}>
                                    {object_type && (object_type === 'custom_card' || object_type === 'customCard') ? (
                                      <p>{u.title ? u.title : u}</p>
                                    ) : (
                                      <a className={`topic-link`}>{u.title ? u.title : u}</a>
                                    )}
                                  </Link>
                                </li>
                              )
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {
          (showLong || additionalClasses.includes('bookmark-card')) && (
            <>
              {linkTarget === '_blank' ? (
                <a href={link ? link : '#'} target={'_blank'} className={`${styles.imageContainer} ${isFile ? styles.iconContainer : ''} ${fgdThumbnailClass ? fgdThumbnailClass : ''} ${object_type === 'stravito' ? styles.fileIcon : ''} ${ga_class}`}>
                  {!video && image && image.src && (
                    <>
                      {isFile ? (
                        <>
                          {object_type === 'stravito' ? (<IconFile />) : typeIcon}
                        </>
                      ) : fgdThumbnail ? (
                        <>
                          <img src={fgdThumbnail} alt={''} />
                        </>
                      ) : (
                        <Image
                          unoptimized={true}
                          src={image.src}
                          alt={image.alt}
                          layout="responsive"
                          objectFit="cover"
                          objectPosition="center"
                          priority={true}
                          width={282}
                          height={161}
                        />
                      )}
                    </>
                  )}
                </a>
              ) : (
                <Link href={link ? link : '#'}>
                  <a className={`${styles.imageContainer} ${isFile ? styles.iconContainer : ''} ${fgdThumbnailClass ? fgdThumbnailClass : ''} ${object_type === 'stravito' ? styles.fileIcon : ''} ${ga_class}`}>
                    {!video && image && image.src && (
                      <>
                        {isFile ? (
                          <>
                            {object_type === 'stravito' ? (<IconFile />) : typeIcon}
                          </>
                        ) : fgdThumbnail ? (
                          <>
                            <img src={fgdThumbnail} alt={''} />
                          </>
                        ) : (
                          <Image
                            unoptimized={true}
                            src={image.src}
                            alt={image.alt}
                            layout="responsive"
                            objectFit="cover"
                            objectPosition="center"
                            priority={true}
                            width={282}
                            height={161}
                          />
                        )}
                      </>
                    )}
                  </a>
                </Link>
              )}
            </>
          )
        }
      </div >
    );
  }
}

export default Card;

export const ReactionIcons = ({ reactions }: { reactions: IfaceReactions }) => {
  const [largestReaction, setLargestReaction] = useState("thumbs_up");
  const [currentLeader, setCurrentLeader] = useState("none");
  // const [leaderCount, setLeaderCount] = useState(0)

  useEffect(() => {
    let thisLeader = "none";
    let leaderCount = 0;

    if (reactions.thumbs_up > leaderCount) {
      thisLeader = "thumbs_up";
      leaderCount = reactions.thumbs_up;
    }
    if (reactions.shamelessly_steal > leaderCount) {
      thisLeader = "shamelessly_steal";
      leaderCount = reactions.shamelessly_steal;
    }
    if (reactions.i_want_more > leaderCount) {
      thisLeader = "i_want_more";
      leaderCount = reactions.i_want_more;
    }
    if (reactions.thumbs_down > leaderCount) {
      thisLeader = "thumbs_down";
      leaderCount = reactions.thumbs_down;
    }

    setCurrentLeader(thisLeader);
  }, [reactions]);

  return (
    <>
      {currentLeader === "thumbs_up" && (
        <div className="reactionIcon thumbsUp">
          <IconThumbsUp />
        </div>
      )}
      {currentLeader === "thumbs_down" && (
        <div className="reactionIcon thumbsDown">
          <IconThumbsDown />
        </div>
      )}
      {currentLeader === "i_want_more" && (
        <div className="reactionIcon thumbsWantMore">
          <IconWantMore />
        </div>
      )}
      {currentLeader === "shamelessly_steal" && (
        <div className="reactionIcon thumbsShamelesslySteal">
          <IconHamburglar />
        </div>
      )}
    </>
  );
};

interface IfaceReactions {
  thumbs_up: number;
  thumbs_down: number;
  shamelessly_steal: number;
  i_want_more: number;
}
